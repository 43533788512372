/**
 * @name: ImgCpt
 * @author: sgb004
 * @version: 0.3.0
 */
import { ListenerScroll } from '../../utils/listener-scroll';
export class ImgCpt extends HTMLElement {
	picture;
	loading;
	listenerScrollId = 0;

	constructor() {
		super();

		this.shadow = this.attachShadow({ mode: 'open' });

		const style = document.createElement('style');
		style.textContent = `:host {display: inline-block; position: relative;} picture{ position: relative;} picture, img { display: block; width: inherit; height: inherit; max-width: inherit; max-height: inherit; border-radius: inherit;} picture, loading-cpt{ opacity: 1; transition-timing-function: ease-out; transition: opacity 1s; } loading-cpt{ position: absolute; top: 0; right: 0; width: 100%; height: 100%; } picture[hide], loading-cpt[hide]{ opacity: 0; }`;
		this.shadow.appendChild(style);

		this.picture = document.createElement('picture');
		this.picture.setAttribute('hide', true);

		this.loading = document.createElement('loading-cpt');
		this.loading.setAttribute('hide', true);

		this.shadow.appendChild(this.loading);
		this.shadow.appendChild(this.picture);
		
		this.listenerScrollId = ListenerScroll.addEventListenerScroll(this.checkStage.bind(this));
	}

	connectedCallback(){
		ListenerScroll.callEventScroll(this.listenerScrollId);
	}

	imgLoad() {
		this.picture.removeAttribute('hide');
		this.loading.setAttribute('hide', true);
		this.setAttribute('loaded', true);
	}

	checkStage() {
		if( super.innerHTML != '' ){
			let ett = this.getBoundingClientRect().top - window.innerHeight;
			if (window.pageYOffset >= ett) {
				this.picture.innerHTML = super.innerHTML;
				this.picture.setAttribute('hide', true);
				this.loading.removeAttribute('hide');

				const sources = this.picture.querySelectorAll('source, img');
				for(let i=0; i<sources.length; i++){
					sources[i].onload = this.imgLoad.bind(this);
				}

				return true;
			}
		}
		return false;
	}
	
	set innerHTML(html){
		super.innerHTML = html;
		ListenerScroll.callEventScroll(this.listenerScrollId);
	}

	get innerHTML(){
		return this.picture.innerHTML;
	}
}
