/**
 * @name: ListenerScroll
 * @author: sgb004
 * @version: 0.1.0
 */

export class ListenerScroll {
	static listenerScrollAdded = false;
	static listenerScroll = [];

	static addEventListenerScroll(fn) {
		const id = this.listenerScroll.length;
		this.addListenerScroll();
		this.listenerScroll.push(fn);
		this.callEventScroll(id);
		return id;
	}

	static addListenerScroll() {
		if (!this.listenerScrollAdded) {
			window.addEventListener('scroll', this.eventScroll.bind(this));
			this.listenerScrollAdded = true;
		}
	}

	static eventScroll() {
		for (const id in this.listenerScroll) {
			this.callEventScroll(id);
		}
	}

	static callEventScroll(id){
		if (this.listenerScroll[id]()) {
			this.deleteEventScroll(id);
		}
	}

	static deleteEventScroll(id){
		delete this.listenerScroll[id];
	}
}
