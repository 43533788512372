<ul>
	<li #prev>
		<a href=""><span class="icon-left"></span></a>
	</li>
	<li>
		<a routerLink="/"><span class="icon-home"></span></a>
	</li>
	<li>
		<a routerLink="/contact" class="link-icon-mail"><span class="icon-mail"></span></a>
	</li>
	<li #external>
		<a href="" target="_blank"><span class="icon-external"></span></a>
	</li>
	<li #next>
		<a href=""><span class="icon-right"></span></a>
	</li>
</ul>