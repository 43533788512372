import { Component, OnInit } from '@angular/core';
import { Params, ActivatedRoute } from '@angular/router';

import { PortafolioApiService } from '../portafolio-api.service';

@Component({
	selector: 'project',
	templateUrl: './project.component.html',
	styleUrls: ['./project.component.css'],
})
export class ProjectComponent implements OnInit {
	constructor(
		public router: ActivatedRoute,
		private portafolioApiService: PortafolioApiService
	) {
		this.router.params.subscribe((params: Params) => {
			console.log(params.id);
			this.portafolioApiService.getProject(params.id).subscribe((data) => {
				console.log(data);
			});
		});
	}

	ngOnInit(): void {}
}
