import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	ViewContainerRef,
	ComponentFactoryResolver,
	ComponentFactory,
	ComponentRef,
} from '@angular/core';
import { Project } from '../Project';

// @ts-ignore
import { ImgCpt } from 'sgb004-webutils/components/img-cpt/img-cpt';

@Component({
	selector: 'project-thumbnail',
	templateUrl: './project-thumbnail.component.html',
	styleUrls: ['./project-thumbnail.component.css'],
})
export class ProjectThumbnailComponent implements OnInit {
	@Input()
	//@ts-ignore
	project: Project;
	id: number = 0;
	name: string = '';
	link: string = '';

	// @ts-ignore
	@ViewChild('cover') cover: ImgCpt;
	// @ts-ignore
	@ViewChild('link', { read: ViewContainerRef }) container: ViewContainerRef;

	constructor(private resolver: ComponentFactoryResolver) {}

	ngOnInit(): void {
		this.id = this.project.id;
		this.name = this.project.name;
		this.link = this.project.link;
	}

	ngAfterViewInit() {
		const cover = this.cover.nativeElement;
		let urlJpg = this.project.cover.replace('webp', 'jpg');
		cover.innerHTML =
			'<source srcset="' +
			this.project.cover +
			'" type="image/webp" /><img src="' +
			urlJpg +
			'" />';
		/*
		cover.querySelector('source').setAttribute('srcset', this.project.cover);
		cover.querySelector('img').setAttribute('src', urlJpg);
		*/
	}
}
