class LoadingCpt extends HTMLElement {
	constructor() {
		super();

		this.shadow = this.attachShadow({ mode: 'open' });
		const style = document.createElement('style');
		style.textContent = `@keyframes ldio-ylsu35fcnv8 {
			0% {
			  top: 48px;
			  left: 48px;
			  width: 0;
			  height: 0;
			  opacity: 1;
			}
			100% {
			  top: 9px;
			  left: 9px;
			  width: 78px;
			  height: 78px;
			  opacity: 0;
			}
		  }.ldio-ylsu35fcnv8 div {
			position: absolute;
			border-width: 2px;
			border-style: solid;
			opacity: 1;
			border-radius: 50%;
			animation: ldio-ylsu35fcnv8 1s cubic-bezier(0,0.2,0.8,1) infinite;
		  }.ldio-ylsu35fcnv8 div:nth-child(1) {
			border-color: #ffc857;
			animation-delay: 0s;
		  }
		  .ldio-ylsu35fcnv8 div:nth-child(2) {
			border-color: #177e89;
			animation-delay: -0.5s;
		  }
		  .loadingio-spinner-ripple-uuypsp2hti {
			width: 100px;
			height: 100px;
			display: inline-block;
			overflow: hidden;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
		  }
		  .ldio-ylsu35fcnv8 {
			width: 100%;
			height: 100%;
			position: relative;
			transform: translateZ(0) scale(1);
			backface-visibility: hidden;
			transform-origin: 0 0; /* see note above */
		  }
		  .ldio-ylsu35fcnv8 div { box-sizing: content-box; }`;

		const container = document.createElement('div');
		container.innerHTML = `<div class="loadingio-spinner-ripple-uuypsp2hti"><div class="ldio-ylsu35fcnv8">
		<div></div><div></div>
		</div></div>`;

		this.shadow.innerHTML = `<div class="loadingio-spinner-ripple-uuypsp2hti"><div class="ldio-ylsu35fcnv8">
		<div></div><div></div>
		</div></div>`;
		this.shadow.appendChild(style);
		//this.shadow.appendChild(container);
	}
}

customElements.define('loading-cpt', LoadingCpt);
