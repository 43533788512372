import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Project } from './Project';

@Injectable({
	providedIn: 'root',
})
export class PortafolioApiService {
	constructor(private httpClient: HttpClient) {}

	getProjects() {
		return this.httpClient.get<Project[]>('http://127.0.0.1:8000/api/projects');
	}

	getProject(id: number) {
		return this.httpClient.get<Project>(
			'http://127.0.0.1:8000/api/project/' + id
		);
	}
}
