<div class="avatar">
	<img-cpt src="./assets/imgs/avatar.webp" extalt="png" id="prueba">
		<source srcset="./assets/imgs/avatar.webp" type="image/webp" />
		<img src="./assets/imgs/avatar.png" />
	</img-cpt>
</div>

<h1>Desarrollador web</h1>

<h2>Salvador Gonzalez Blanco</h2>

<div class="col-8 intro-text">
	<p>
		Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus, nulla
		ut commodo sagittis, sapien dui mattis dui, non pulvinar lorem felis nec
		erat Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc maximus,
		nulla ut commodo sagittis, sapien dui mattis dui, non pulvinar lorem felis
		nec erat
	</p>
</div>

<div #projectView class="col-12 projects">
	<project-thumbnail
		*ngFor="let project of projectsData"
		[project]="project"
	></project-thumbnail>
</div>
