import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit,
} from '@angular/core';

@Component({
	selector: 'header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
	@Input() linkPrev: string = '';
	@Input() linkNext: string = '';
	@Input() linkExternal: string = '';
	@ViewChild('prev') prev!: ElementRef;
	@ViewChild('next') next!: ElementRef;
	@ViewChild('external') external!: ElementRef;

	constructor() {}

	ngOnInit(): void {}

	ngAfterViewInit() {
		if (this.linkPrev === '') {
			this.prev.nativeElement.classList.add('hide');
		} else {
			this.prev.nativeElement.querySelector('a').href = this.linkPrev;
			this.prev.nativeElement.classList.remove('hide');
		}
		if (this.linkNext === '') {
			this.next.nativeElement.classList.add('hide');
		} else {
			this.next.nativeElement.querySelector('a').href = this.linkNext;
			this.next.nativeElement.classList.remove('hide');
		}
		if (this.linkExternal === '') {
			this.external.nativeElement.classList.add('hide');
		} else {
			this.external.nativeElement.querySelector('a').href = this.linkExternal;
			this.external.nativeElement.classList.remove('hide');
		}
	}
}
