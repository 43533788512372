import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { PortafolioApiService } from '../portafolio-api.service';
import { Project } from '../Project';
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
	projectsData: Project[];
	//@ts-ignore
	@ViewChild('projectView') projectView: ElementRef;

	constructor(private portafolioApiService: PortafolioApiService) {
		this.projectsData = [];

		this.portafolioApiService.getProjects().subscribe((data) => {
			this.projectsData = data;
		});
	}

	ngOnInit(): void {}

	ngAfterViewInit() {
		console.log(this.projectView.nativeElement.innerHeight);
	}
}
