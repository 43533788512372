import { Component } from '@angular/core';
// @ts-ignore
import { webFontLoader } from 'google-webfont-loader';

// @ts-ignore
import 'sgb004-webutils/components/img-cpt/';

import './loading-cpt';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css'],
})
export class AppComponent {
	headerLinkPrev: string = '';
	headerLinkNext: string = '';
	headerLinkExternal: string = '';
	title = 'Portafolio';

	constructor() {
		webFontLoader({
			google: {
				families: ['Raleway:400,700&display=swap'],
			},
		});
	}
}
