import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { WorkPortfolioItemComponent } from './work-portfolio-item/work-portfolio-item.component';

import { PortafolioApiService } from './portafolio-api.service';
import { ProjectComponent } from './project/project.component';
import { ProjectThumbnailComponent } from './project-thumbnail/project-thumbnail.component';

@NgModule({
	declarations: [
		AppComponent,
		HeaderComponent,
		HomeComponent,
		ContactComponent,
		WorkPortfolioItemComponent,
		ProjectComponent,
		ProjectThumbnailComponent,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
	imports: [BrowserModule, AppRoutingModule, HttpClientModule],
	providers: [PortafolioApiService],
	bootstrap: [AppComponent],
})
export class AppModule {}
